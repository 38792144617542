import Image from "next/image";
import { useState } from "react";
import { getImage, getImagePlaceholder } from "../../lib/getImage";

interface Home {
  banner: Banner;
  projects: Projects;
  roadmap: RoadMap;
  partners: Partners;
  community: Community;
  footer: Footer[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Banner = ({ data }: { data: Home }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen((state) => !state);
  };

  return (
    <section className="py-4 relative overflow-hidden">
      <Image src="/light1.png" alt="light" width={900} height={900} className="absolute -top-96 -right-96" />
      <div className="container relative px-6 py-10 md:py-16 ">
        <Image
          src="/images/Afrique.svg"
          alt="light"
          width={750}
          height={750}
          className="absolute md:-right-20 top-36 opacity-10"
        />

        <Image
          src="/light2.png"
          alt="light"
          width={850}
          height={850}
          className="absolute -left-40 top-[460px]  animate-blob"
        />
        <Image
          src="/light4.png"
          alt="light"
          width={1350}
          height={1850}
          className="absolute -left-96 top-[360px]  animate-spin"
        />

        <div className="flex flex-col mt-32 min-h-[900px] relative z-10">
          {/* Banner */}
          <div className="">
            <h2 className="uppercase text-2xl text-[#ffb200] text-center font-bold"> {data?.banner?.subTitle} </h2>
            <h1 className="text-5xl leading-tight md:leading-tight mx-auto md:text-8xl md:max-w-5xl my-8 md:mb-8 md:mt-0 capitalize text-center text-white font-bold">
              {data?.banner?.title}
            </h1>
            <h3 className="text-2xl text-center text-slate-300 mx-auto md:max-w-4xl">{data?.banner?.description}</h3>
          </div>

          {/* Projects */}
          <div className="relative">
            <div className="md:pt-52 pt-24 flex justify-between flex-col-reverse md:flex-row ">
              <div className="md:max-w-2xl">
                <h2 className="text-5xl leading-tight md:leading-none md:text-6xl my-8 md:mb-8 md:mt-0 capitalize text-white font-bold">
                  {data?.projects?.title}
                </h2>
                <h3 className="text-2xl text-slate-300 md:max-w-lg">{data?.projects?.description}</h3>
              </div>
              <h2 className="text-9xl font-extrabold md:px-16 md:pt-16"> {data?.projects?.count} </h2>
            </div>

            <div className="relative py-10 md:py-16">
              {data?.projects?.media.url && (
                <Image
                  src={getImage(data?.projects?.media)}
                  blurDataURL={getImagePlaceholder(data?.projects?.media)}
                  placeholder="blur"
                  alt="Updev Africa"
                  fill
                  className="mx-auto opacity-5 object-center object-scale-down animate-blob"
                />
              )}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {data?.projects?.items?.map((project, i) => (
                  <div
                    key={project?.id}
                    className="col-auto flex gap-6 flex-col w-full px-4 py-6 bg-gray-400 rounded-lg bg-clip-padding bg-opacity-10 relative  hover:bg-opacity-20"
                  >
                    <div className="flex justify-between">
                      {project?.media.url && (
                        <Image
                          src={getImage(project.media)}
                          placeholder="blur"
                          blurDataURL={getImagePlaceholder(project.media)}
                          alt="light"
                          width={100}
                          height={100}
                          className="object-contain"
                        />
                      )}
                      <div className="px-3 py-0.5 uppercase text-xs flex justify-center items-center bg-white rounded-full bg-opacity-20">
                        {project.name}
                      </div>
                    </div>
                    <p
                      className="text-gray-400 project"
                      dangerouslySetInnerHTML={{
                        __html: project?.description || "",
                      }}
                    />
                    <div className="flex justify-between">
                      <div className="flex gap-4">
                        <div className="text-sm"> {project.type} </div>
                        <div className="text-sm text-[#ffb200]"> {project.status} </div>
                      </div>
                      <a
                        className="text-sm cursor-pointer hover:text-[#ffb200]"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={project?.link || "#"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Road map */}
          <div className="relative md:pt-40 pt-24">
            <Image
              src="/light4.png"
              alt="light"
              width={680}
              height={680}
              className="absolute top-96 -left-[32rem] object-left"
            />
            {data?.roadmap?.media?.url && (
              <Image
                src={getImage(data?.roadmap?.media)}
                alt="light"
                width={650}
                height={650}
                className="absolute top-96 -left-[32rem] opacity-5 object-left object-scale-down animate-spin"
              />
            )}
            <div className="">
              <h2 className="text-5xl md:max-w-md leading-tight md:leading-none md:text-6xl my-8 md:mb-8 md:mt-0 capitalize text-white font-bold">
                {data?.roadmap?.title}
              </h2>
              <h3 className="text-2xl text-slate-300 md:max-w-lg">{data?.roadmap?.description}</h3>
            </div>

            <div className="py-3 sm:max-w-4xl sm:float-right w-full relative px-2 sm:px-0">
              <Image src="/light4.png" alt="light" fill className="mx-auto object-center animate-blob" />
              <div className="relative text-slate-300 antialiased text-sm font-semibold">
                <div className="block w-1 bg-[#ffb200] opacity-20 md:opacity-40  absolute h-full left-1/2 transform -translate-x-1/2" />

                {data?.roadmap?.timeline?.map((timeline, i) => (
                  <div key={i} className="mt-6 sm:mt-0 -sm:mb-10 ">
                    <div className="flex flex-col sm:flex-row items-center">
                      <div
                        className={classNames(
                          "flex w-full mx-auto items-center",
                          (i + 1) % 2 ? "justify-start" : "justify-end"
                        )}
                      >
                        <div className={classNames("w-full sm:w-1/2", (i + 1) % 2 ? "sm:pr-8" : "sm:pl-8")}>
                          <div className="p-4 flex flex-col gap-3 md:gap-7">
                            <h3
                              className={classNames(
                                "text-2xl text-white font-extrabold",
                                (i + 1) % 2 ? "md:text-right" : "text-left"
                              )}
                            >
                              {timeline?.date}{" "}
                            </h3>
                            <h3 className="text-2xl text-[#ffb200]"> {timeline?.title} </h3>
                            <p>{timeline?.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#ffb200]  border-[#ffb200] opacity-40 border-4 w-4 h-4 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Partners */}
          <div className="relative md:pt-40 pt-24">
            <Image
              src="/light4.png"
              alt="light"
              fill
              className="mx-auto object-center object-scale-down animate-blob"
            />
            <div className="relative">
              <h2 className="text-5xl text-center leading-tight md:leading-none md:text-6xl my-8 md:mb-8 md:mt-0 capitalize text-white font-bold">
                {data?.partners?.title}
              </h2>
              <h3 className="text-2xl text-center text-slate-300">{data?.partners?.description}</h3>
            </div>
            <div className="flex flex-wrap relative justify-center items-center my-8 md:my-16 gap-2">
              {data?.partners?.medias?.map((partner, i) => (
                <div key={i} className="flex h-auto w-44 md:w-40 md:h-32">
                  <Image
                    src={getImage(partner)}
                    alt={partner?.alternativeText || "Updev Africa"}
                    width={partner?.width}
                    height={partner?.height}
                    className="object-center object-contain grayscale hover:grayscale-0 "
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Our Community */}
          <div className="relative">
            <Image
              width={700}
              height={700}
              src="/light4.png"
              alt="light"
              className="mx-auto object-center absolute top-36 left-0 right-0 object-scale-down animate-blob"
            />
            {data?.community?.media?.url && (
              <Image
                width={400}
                height={400}
                src={getImage(data?.community?.media)}
                alt="light"
                className="mx-auto opacity-5 object-center absolute top-96 left-0 right-0 object-scale-down animate-spin"
              />
            )}
            <div className="relative md:pt-52 pt-24 flex justify-between flex-col md:flex-row ">
              <div className="relative">
                <h2 className="text-5xl md:max-w-md leading-tight md:leading-none md:text-6xl my-8 md:mt-0 capitalize text-white font-bold">
                  {data?.community?.title}
                </h2>
                <h3 className="text-2xl text-slate-300 md:max-w-lg">{data?.community?.description}</h3>
              </div>
              <h2 className="text-9xl font-extrabold md:px-16 pt-8 md:pt-16"> {data?.community?.count} </h2>
            </div>
            <div className="flex justify-between flex-col md:flex-row gap-8 md:gap-56 mt-16 relative">
              <Image
                width={1200}
                height={1200}
                src="/light4.png"
                alt="light"
                className="absolute -top-[20rem] -right-[46rem] animate-spin"
              />
              <div className="flex gap-6 flex-col h-fit px-4 py-6 bg-gray-400 rounded-lg bg-opacity-10 relative  hover:bg-opacity-20">
                <div className="flex justify-between items-center">
                  <div className="flex gap-4">
                    {data?.community?.card?.media?.url && (
                      <Image
                        src={getImage(data?.community?.card?.media)}
                        placeholder="blur"
                        blurDataURL={getImagePlaceholder(data?.community?.card?.media)}
                        alt="light"
                        width={40}
                        height={40}
                        className="object-contain"
                      />
                    )}
                    <div className="px-3 py-1 text-lg">
                      <h3 className="text-white font-bold">{data?.community?.card?.title}</h3>
                    </div>
                  </div>
                  <a
                    href={data?.community?.card?.link || "#"}
                    className="text-sm cursor-pointer hover:text-[#ffb200]"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </a>
                </div>
                <p className="text-gray-300">{data?.community?.card?.description}</p>
              </div>
              <div className="flex w-max-2xl px-4 py-6 flex-col gap-8 bg-gray-400 rounded-lg bg-opacity-10 relative  hover:bg-opacity-20">
                {data?.community?.RS.map((item, i) => (
                  <div key={i} className="flex items-center gap-2 justify-between w-full">
                    <div className="flex items-center gap-4">
                      {item?.media?.url && (
                        <Image
                          src={getImage(item?.media)}
                          alt="light"
                          width={40}
                          height={40}
                          className="object-contain"
                        />
                      )}
                      <div className="text-lg text-slate-300 font-semibold">{item?.title}</div>
                    </div>
                    <a
                      href={item?.link || "#"}
                      className="text-sm cursor-pointer hover:text-[#ffb200]"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </a>
                  </div>
                ))}
                <h6 className=" text-slate-300 md:max-w-lg">{data?.community?.caption}</h6>
              </div>
            </div>
          </div>

          {/* Footer */}
          <footer className="py-4">
            <div className="flex flex-col md:flex-row mt-2 gap-4">
              {data?.footer?.map((item, i) => (
                <a
                  key={i}
                  href={item?.path || "#"}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="hover:text-[#ffb200]"
                >
                  {item?.label}
                </a>
              ))}
            </div>
            <hr className="my-3 border-gray-200 sm:mx-auto dark:border-gray-700 lg:mb-6" />
            <div className="sm:flex sm:items-center sm:justify-between">
              <span className="text-sm text-gray-300 sm:text-center dark:text-gray-400">
                ©{" "}
                <a
                  href="https://uptodatedevelopers.com"
                  rel="nofollow noreferrer"
                  target="_blank"
                  aria-label="Uptodate developers"
                  className="hover:underline text-white"
                >
                  Uptodate Developers
                </a>
              </span>
              <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">{new Date().getFullYear()}</div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default Banner;
