import { GetStaticProps } from "next";
import Banner from "../components/banner/Banner";
import NavBar from "../components/navbar/NavBar";
import SEO from "../components/seo/SEO";
import { getData } from "../lib/getData";
import { getImage } from "../lib/getImage";

export default function Home({ menu, home }: any) {
  return (
    <>
      <SEO
        title="Updev Africa"
        description={home?.company?.description}
        name={home?.company?.name}
        logo={getImage(home?.company?.logo)}
        favicon={getImage(home?.company?.favicon)}
        image={getImage(home.company?.ogimage)}
        phone={home?.company?.phone}
        GA_MEASUREMENT_ID={home?.company?.GA_MEASUREMENT_ID}
      />
      <NavBar data={menu?.items} logo={home?.company?.logo} />
      <Banner data={home} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const menu = await getData("menu", locale);
  const home = await getData("home", locale);

  return {
    props: {
      menu: menu.data,
      home: home.data,
    },
    revalidate: 10800,
  };
};
