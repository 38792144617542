export function getImage(media: Media) {
  return `${process.env.NEXT_PUBLIC_BASE_URL}${media?.url}`;
}

export function getImageByURL(url: string) {
  return `${process.env.NEXT_PUBLIC_BASE_URL}${url}`;
}

export function getImagePlaceholder(media: Media) {
  return `${process.env.NEXT_PUBLIC_BASE_URL}${media?.placeholder}`;
}
