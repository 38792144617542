import { Menu, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useI18n } from "../../hooks/useI18n";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const LanguageToggle = () => {
  const { locale } = useRouter();
  const switchLanguages = useI18n<"fr" | "en">();

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex outline-none font-medium text-lg">
          {({ open }) => (
            <>
              <span className="sr-only">Open langue menu</span>
              <span className="inline-flex items-center justify-center px-3 py-1 ">
                {locale === "fr" ? "En" : "Fr"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className={`w-4 h-4 ml-2 transition-all duration-500 mt-1 ${open ? "transform rotate-180" : ""}`}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </span>
            </>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-[#000E1F] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <span
                onClick={() => {
                  switchLanguages("en");
                }}
                className={classNames(
                  active ? "bg-gray-100 dark:bg-slate-700 " : "",
                  "block px-4 py-2 w-full cursor-pointer"
                )}
              >
                English
              </span>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                onClick={() => {
                  switchLanguages("fr");
                }}
                className={classNames(
                  active ? "bg-gray-100 dark:bg-slate-700" : "",
                  "block px-4 py-2 w-full cursor-pointer"
                )}
              >
                Français
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageToggle;
